import React from 'react'

import * as contactStyles from './contact.module.css'

import onstageImage from '../../images/Onstage.png'
import mailtoImage from '../../images/mailto-100px.png'
import linkedinImage from '../../images/In-White-121px.png'
import twitterImage from '../../images/Twitter-logo-120px.png'
import githubImage from '../../images/GitHub-Mark-Light-120px-plus.png'
import dribbbleImage from '../../images/Dribbble-120px.png'

const Contact = () => (
  <section
    className={`${contactStyles.container}  ${contactStyles.contactBar}`}
  >
    <div className={contactStyles.block}>
      <img src={onstageImage} title="Me onstage" alt="Me onstage" />
      <div className={contactStyles.overlay}>
        <h1 className={contactStyles.heading}>Say Hello!</h1>
        <div className={contactStyles.contacts}>
          <a className={contactStyles.mailto} href="mailto:laitine@iki.fi">
            <img src={mailtoImage} title="Mail" alt="Mail" />
          </a>
          <a
            className={contactStyles.linkedin}
            href="https://www.linkedin.com/in/nlaitinen"
          >
            <img src={linkedinImage} title="Linkedin" alt="Linkedin" />
          </a>
          <a
            className={contactStyles.twitter}
            href="https://twitter.com/laitineio"
          >
            <img src={twitterImage} title="Twitter" alt="Twitter" />
          </a>
          <a className={contactStyles.github} href="https://github.com/laitine">
            <img src={githubImage} title="Github" alt="Github" />
          </a>
          <a
            className={contactStyles.dribbble}
            href="https://dribbble.com/laitine"
          >
            <img src={dribbbleImage} title="Dribbble" alt="Dribbble" />
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default Contact
