// extracted by mini-css-extract-plugin
export var triangleBar = "frontpage-module--triangle-bar--rU5hK";
export var skillsBar = "frontpage-module--skills-bar--bGAI0";
export var logicBar = "frontpage-module--logic-bar--eY-Ph";
export var heading = "frontpage-module--heading--KTas-";
export var indentText = "frontpage-module--indent-text--kUkSm";
export var triangleBlock = "frontpage-module--triangle-block--Bsg65";
export var interestsTriangle = "frontpage-module--interests-triangle--Y3cQn";
export var interestsCircle1 = "frontpage-module--interests-circle-1--OggQ-";
export var interestsCircle2 = "frontpage-module--interests-circle-2--up6zB";
export var interestsCircle3 = "frontpage-module--interests-circle-3--J8Q1T";
export var triangleTitle = "frontpage-module--triangle-title--O2Nw0";
export var topTitle = "frontpage-module--top-title--8qdIJ";
export var leftTitle = "frontpage-module--left-title--QCWg9";
export var rightTitle = "frontpage-module--right-title--jhO3T";
export var shape = "frontpage-module--shape--aT-+E";
export var leftTriangleShape = "frontpage-module--left-triangle-shape--gdJJW";
export var topTriangleShape = "frontpage-module--top-triangle-shape--Wp5wh";
export var rightTriangleShape = "frontpage-module--right-triangle-shape--dumom";
export var triangleShape = "frontpage-module--triangle-shape--SNP01";
export var littleTriangleShape = "frontpage-module--little-triangle-shape--5WFH8";
export var tinyTriangleShape = "frontpage-module--tiny-triangle-shape--g7clv";
export var circleShape = "frontpage-module--circle-shape--Izyn3";
export var circleShapeShadow = "frontpage-module--circle-shape-shadow--c8ZYN";
export var littleCircleShape = "frontpage-module--little-circle-shape--zuPKg";
export var littleCircleShapeShadow = "frontpage-module--little-circle-shape-shadow--yfcyX";
export var tinyCircleShape = "frontpage-module--tiny-circle-shape--s6Od+";
export var tinyCircleShapeShadow = "frontpage-module--tiny-circle-shape-shadow--8NbeU";
export var squareShape = "frontpage-module--square-shape--XRASK";
export var littleSquareShape = "frontpage-module--little-square-shape--NsJbc";
export var tinySquareShape = "frontpage-module--tiny-square-shape--rAwRV";
export var inlineBlock = "frontpage-module--inline-block--B8e5-";
export var diciplinesBlock = "frontpage-module--diciplines-block--O7LyI";
export var listTitle = "frontpage-module--list-title--wA2Ol";
export var specialtyBlock = "frontpage-module--specialty-block--ViVDX";
export var specialtyTriangle = "frontpage-module--specialty-triangle--TVgAz";
export var shifter = "frontpage-module--shifter--R3PdZ";
export var specialtyCircle1 = "frontpage-module--specialty-circle-1--SZ18x";
export var specialtyCircle2 = "frontpage-module--specialty-circle-2--43aWp";
export var specialtyCircle3 = "frontpage-module--specialty-circle-3---5Zmn";
export var subtitle = "frontpage-module--subtitle--VBCJK";
export var leftSubtitle = "frontpage-module--left-subtitle--jVYOO";
export var rightSubtitle = "frontpage-module--right-subtitle--4Tltu";
export var bottomSubtitle = "frontpage-module--bottom-subtitle--2yikI";
export var logicHeading = "frontpage-module--logic-heading--+dq+6";
export var logicGrid = "frontpage-module--logic-grid--428pm";
export var logicBox = "frontpage-module--logic-box--PaGSD";
export var hex1 = "frontpage-module--hex1--Nx-+w";
export var hex2 = "frontpage-module--hex2--sOWs1";
export var hex3 = "frontpage-module--hex3--MQHCn";
export var hex4 = "frontpage-module--hex4--3z62y";
export var hex5 = "frontpage-module--hex5--mTEtN";
export var hex6 = "frontpage-module--hex6--IFEFe";
export var hex7 = "frontpage-module--hex7--QA+2d";
export var hex8 = "frontpage-module--hex8--Okwy8";
export var hex9 = "frontpage-module--hex9--LbMSu";