// extracted by mini-css-extract-plugin
export var container = "contact-module--container--Wu9PK";
export var block = "contact-module--block--Kr--Q";
export var contactBar = "contact-module--contact-bar--awt3a";
export var overlay = "contact-module--overlay--rXIdG";
export var heading = "contact-module--heading--uHrbF";
export var contacts = "contact-module--contacts--gzYAR";
export var mailto = "contact-module--mailto--bHyaP";
export var linkedin = "contact-module--linkedin--Ym5au";
export var twitter = "contact-module--twitter--NsysL";
export var github = "contact-module--github--sdB2P";
export var dribbble = "contact-module--dribbble--iODHl";