import React from 'react'

import * as heroStyles from './hero.module.css'
import Surface from '../surface'

const Hero = () => (
  <div className={heroStyles.container}>
    <Surface />
    <div className={heroStyles.block}>
      <h2 className={heroStyles.heading}>
        Hi, I'm Niko. I help organizations move towards a sustainable future
        through design and resilience.
      </h2>
    </div>
  </div>
)

export default Hero
