import React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Contact from '../components/contact'

import * as frontpageStyles from '../layouts/frontpage.module.css'

const IndexPage = () => (
  <Layout>
    <Hero />
    <section>
      <div className={frontpageStyles.triangleBar}>
        <div className={frontpageStyles.interestsCircle1}></div>
        <div className={frontpageStyles.interestsCircle2}></div>
        <div className={frontpageStyles.interestsCircle3}></div>
        <div className={frontpageStyles.triangleBlock}>
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.leftTriangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.topTriangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.rightTriangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.triangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.littleTriangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.tinyTriangleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.circleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.circleShapeShadow}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.littleCircleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.littleCircleShapeShadow}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.tinyCircleShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.tinyCircleShapeShadow}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.squareShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.littleSquareShape}`}
          />
          <div
            className={`${frontpageStyles.shape} ${frontpageStyles.tinySquareShape}`}
          />

          <div className={frontpageStyles.interestsTriangle} />
          <h2
            className={`${frontpageStyles.triangleTitle} ${frontpageStyles.topTitle}`}
          >
            Design
          </h2>
          <h2
            className={`${frontpageStyles.triangleTitle} ${frontpageStyles.leftTitle}`}
          >
            Organizations
          </h2>
          <h2
            className={`${frontpageStyles.triangleTitle} ${frontpageStyles.rightTitle}`}
          >
            Systems
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div className={frontpageStyles.skillsBar}>
        <div className={frontpageStyles.inlineBlock}>
          <div
            className={`${frontpageStyles.diciplinesBlock}  ${frontpageStyles.indentText}`}
          >
            <h3 className={frontpageStyles.heading}>My Disciplines</h3>
            <h4 className={frontpageStyles.listTitle}>UX Design</h4>
            <h4 className={frontpageStyles.listTitle}>Interaction Design</h4>
            <h4 className={frontpageStyles.listTitle}>
              Information Architecture
            </h4>
            <h4 className={frontpageStyles.listTitle}>Design Research</h4>
            <h4 className={frontpageStyles.listTitle}>Prototyping</h4>
            <h4 className={frontpageStyles.listTitle}>User Research</h4>
            <h4 className={frontpageStyles.listTitle}>Visual Design</h4>
            <h4 className={frontpageStyles.listTitle}>Motion Design</h4>
            <h4 className={frontpageStyles.listTitle}>
              JS, CSS, HTML &amp; NodeJS
            </h4>
          </div>
          <div className={frontpageStyles.specialtyBlock}>
            <div className={frontpageStyles.specialtyCircle1}></div>
            <div className={frontpageStyles.specialtyCircle2}></div>
            <div className={frontpageStyles.specialtyCircle3}></div>
            <div className={frontpageStyles.specialtyTriangle} />
            <h3
              className={`${frontpageStyles.subtitle} ${frontpageStyles.leftSubtitle}`}
            >
              UX Strategy
            </h3>
            <h3
              className={`${frontpageStyles.subtitle} ${frontpageStyles.rightSubtitle}`}
            >
              DesignOps
            </h3>
            <h3
              className={`${frontpageStyles.subtitle} ${frontpageStyles.bottomSubtitle}`}
            >
              Design Systems
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className={frontpageStyles.logicBar}>
        <h3
          className={`${frontpageStyles.heading} ${frontpageStyles.logicHeading}`}
        >
          My Logic
        </h3>
        <div className={frontpageStyles.logicGrid}>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex1}`}
          >
            <h3>Vision</h3>
            <p>The future outcome of your organization</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex2}`}
          >
            <h3>Alignment</h3>
            <p>The collective direction</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex3}`}
          >
            <h3>Culture</h3>
            <p>Shared momentum</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex4}`}
          >
            <h3>Practices</h3>
            <p>Flow of continuous actions</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex5}`}
          >
            <h3>Research</h3>
            <p>There is always uncertainty</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex6}`}
          >
            <h3>Collaboration</h3>
            <p>Sharing is building knowledge on top of knowledge</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex7}`}
          >
            <h3>Sustainability</h3>
            <p>Sustainability is the base of evolution</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex8}`}
          >
            <h3>Continuous Learning</h3>
            <p>Improving is never done</p>
          </div>
          <div
            className={`${frontpageStyles.logicBox} ${frontpageStyles.hex9}`}
          >
            <h3>?</h3>
            <p>Finding out...</p>
          </div>
        </div>
      </div>
    </section>
    <Contact />
  </Layout>
)

export default IndexPage
