import React, { useEffect, useRef, useState } from 'react'
import anime from 'animejs'

import onWindowResize from '../hooks/OnWindowResize'

import * as surfaceStyles from './surface.module.css'

const cubeHeight = 50
const cubeWidth = 50

const onMouseOverAnimate = (event) => {
  anime({
    targets: event.currentTarget,
    top: -10,
    direction: 'alternate',
  })
}

const renderCubes = (height) => {
  const [width] = onWindowResize()
  const columns = Math.round(width / cubeWidth) + 1
  const rows = Math.floor((height / cubeHeight) * 2.1)

  return Array.from(Array(rows).keys()).map((group, y) => {
    return (
      <div key={y} className={surfaceStyles.row}>
        {Array.from(Array(columns).keys()).map((item, x) => {
          return (
            /* eslint-disable */
            <div
              key={x}
              className={`${surfaceStyles.cube}`}
              onMouseEnter={onMouseOverAnimate}
              onTouchMove={onMouseOverAnimate}
              role="figure"
            >
              <div className={`${surfaceStyles.face} ${surfaceStyles.top}`} />
              <div className={`${surfaceStyles.face} ${surfaceStyles.front}`} />
              <div className={`${surfaceStyles.face} ${surfaceStyles.right}`} />
            </div>
            /* eslint-enable */
          )
        })}
      </div>
    )
  })
}

const Surface = () => {
  const [height, setHeight] = useState(null)
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.getBoundingClientRect().height)
    }
  }, [containerRef])

  return (
    <div className={surfaceStyles.container} ref={containerRef}>
      <figure className={surfaceStyles.scene}>{renderCubes(height)}</figure>
    </div>
  )
}

export default Surface
